import * as dd from 'dingtalk-jsapi'
import moment from 'moment'

var totp = window.totp // || require('notp').totp;

export default {
  name: 'MfaCode',
  props: {
    mfa: String
  },
  data() {
    return {
      code: '',
      countdownIndex: ''
    }
  },
  mounted() {
    // this.runMFA()
    this.runCountDown()
  },
  methods: {
    runCountDown() {
      const getCount = (first) => {
        let countdownStart
        const halfMinute = moment().startOf('minute').add('seconds', 30)
        if (moment().isAfter(halfMinute)) {
          countdownStart = halfMinute
        } else {
          countdownStart = halfMinute.subtract('seconds', 30)
        }
        this.countdownIndex = 30 + countdownStart.diff(moment(), 'seconds')
        // console.info(this.countdownIndex)
        if (first || this.countdownIndex === 30) {
          this.generateGoogleAuthToken(this.mfa).then(token => {
            this.code = token
            console.info(token)
          })
        }
      }
      getCount(true)

      setInterval(() => {
        getCount()
      }, 1000)
    },
    runMFA() {
      var countdown = $('.js-countdown').countdown360({
        seconds: 30,
        radius: 16,
        strokeWidth: 4,
        strokeStyle: '#128bed',
        fontColor: '#000',
        label: [''],
        fontSize: '14',
        autostart: false,
        onComplete: () => {
          this.runMFA()
        }
      })
      // countdown.start()
      let countdownStart = moment()
      if (!this.notFirst) {
        const halfMinute = moment().startOf('minute').add('seconds', 31)
        if (moment().isAfter(halfMinute)) {
          countdownStart = halfMinute
        } else {
          countdownStart = halfMinute.subtract('seconds', 30)
        }
        this.notFirst = true
      }
      countdown.start(countdownStart.toDate())
      console.info(countdownStart.toDate())
      // this.generateGoogleAuthToken(this.mfa).then(token => {
      //   this.code = token
      //   console.info(token)
      // })
    },
    generateGoogleAuthToken(key) {
      var bin = this.decodeGoogleAuthKey(key)
      return totp.gen(bin)
    },
    decodeGoogleAuthKey(key) {
      // decode base32 google auth key to binary
      var unformatted = key.replace(/\W+/g, '').toUpperCase()
      var bin = (window.Unibabel || window).base32ToBuffer(unformatted)
      return bin
    },
    copyMfaCode() {
      if (document.execCommand) {
        let inputElement = document.getElementById('mfaCopyCodeId')
        inputElement.select()
        if (document.execCommand('Copy')) {
          dd.device.notification.toast({
            icon: false,
            text: '复制成功',
            duration: 2,
            delay: 0
          })
        }
      }
    }
  }
}
