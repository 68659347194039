import axios from 'axios'
import * as dd from 'dingtalk-jsapi'
import Vue from 'vue'
import { Dialog, Field, Toast } from 'vant'
import MfaCode from '../../components/mfa-code'
Vue.use(Field)
Vue.use(Toast)
let domain = ''
export default {
  name: 'Home',
  components: {
    MfaCode,
    [Dialog.Component.name]: Dialog.Component
  },
  props: {
    msg: String
  },
  data() {
    return {
      showPwd: false,
      showMfaCode: false,
      showResourceDetail: false,
      passwd: '',
      vcode: '',
      userInfo: {},
      resource: [],
      resourceDetail: null,
      vcodeText: '获取验证码'
    }
  },
  created() {
    this.isMobile = !!window.navigator?.userAgent?.match(/(iPhone|iPod|iPad|Android);?/i)
    console.info('isMobile:' + this.isMobile)
  },
  mounted() {
    if (__DEV__) {
      this.setUserInfo({
        name: '沈耀杰',
        avatar: 'https://static-legacy.dingtalk.com/media/lADPDgQ9qtI0p-rNCBXNCBU_2069_2069.jpg',
        email: 'wangjie@greatld.com',
        remark: '',
        mobile: '13358018613',
        userid: '',
        mfa: '6DKOXPWJCO54AQDW'
      })
      this.getResource()
    } else {
      // dd.device.notification.showPreloader({
      //   text: '加载中..',
      //   showIcon: true
      // })
      // setTimeout(function() {
      //   dd.device.notification.hidePreloader()
      // }, 3000)
      axios.get(domain + '/api/config')
        .then((result) => {
          this.corpId = result.data?.data?.corpId
          // dd.ready参数为回调函数，在 环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
          dd.runtime.permission.requestAuthCode({

            corpId: this.corpId, // 三方企业ID
            onSuccess: (result) => {
              axios.get(domain + '/api/login?authCode=' + result.code)
                .then(response => {
                  dd.device.notification.hidePreloader()
                  const userInfo = response.data?.data
                  userInfo.mfa = userInfo.extension
                  this.setUserInfo(userInfo)
                  this.getResource()
                })
                .catch(error => {
                  alert(JSON.stringify(error))
                // console.log(error.message)
                })
            },
            onFail: (err) => {
              alert(JSON.stringify(err))
            }
          })
          dd.config({
            corpId: this.corpId, // 必填，企业ID
            agentId: result.data?.data?.agentId, // 必填，微应用ID
            timeStamp: result.data?.data?.timeStamp, // 必填，生成签名的时间戳
            nonceStr: result.data?.data?.nonceStr, // 必填，自定义固定字符串。
            signature: result.data?.data?.signature, // 必填，签名
            type: 0,
            jsApiList: [
              'biz.chat.openSingleChat'
            ] // 必填，需要使用的jsapi列表，注意：不要带dd。
          })
        })
    }
  },
  methods: {
    setUserInfo(userInfo) {
      console.log(userInfo)
      this.userInfo = userInfo
    },
    setPhone() {
      dd.biz.util.open({
        name: 'profile',
        params: { id: this.userInfo.userid, corpId: this.corpId },
        onSuccess: function(res) {
          // 调用成功时回调
          console.log(res)
        },
        onFail: function(err) {
          // 调用失败时回调
          console.log(err)
        }
      })
    },
    sendVcode() {
      if (this.vcodeText !== '获取验证码') {
        return
      }
      this.vcodeIndex = 60
      this.vcodeText = `${this.vcodeIndex}s`
      this.vcodeTimer = setInterval(() => {
        this.vcodeIndex--
        if (this.vcodeIndex === 0) {
          this.vcodeText = '获取验证码'
          clearInterval(this.vcodeTimer)
        } else {
          this.vcodeText = `${this.vcodeIndex}s`
        }
      }, 1000)
      axios.get(domain + '/api/getVcode', {
        params: {
          mobile: this.userInfo.mobile
        }
      })
        .then(response => {
          Toast('发送成功')
        })
    },
    checkReset(action) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (action === 'confirm') {
            resolve(true)
          } else {
          // 拦截取消操作
            resolve(false)
          }
        }, 500)
      })
    },
    checkResetPwd() {
      if (!this.passwd) {
        Toast('修改失败，请输入密码')
        return false
      }
      if (!this.vcode || this.vcode.length !== 6) {
        Toast('验证码格式错误')
        return false
      }
      const checkPwd = (value) => {
        if (value.length < 8) {
          return '密码最少8个字符'
        } else if (value.length > 18) {
          return '密码最多18个字符'
        } else if (/^[A-Za-z]+$/.test(value)) {
          return '密码不能全是字母'
        } else if (/^\d*$/.test(value)) {
          return '密码不能全是数字'
        } else if (/^[^0-9a-zA-Z]*$/.test(value)) {
          return '密码不能全是字符'
        }
        return false
      }
      const msg = checkPwd(this.passwd)
      if (msg) {
        Toast(msg)
        return false
      }
      return true
    },
    resetPwd(e) {
      if (!this.checkResetPwd()) {
        return
      }
      axios.post(domain + '/api/resetPwd', {
        username: this.userInfo.email.split('@')[0],
        passwd: this.passwd,
        vcode: this.vcode
      })
        .then(response => {
          if (response.data?.success) {
            Toast('修改成功')
            this.passwd = ''
            this.vcode = ''
            this.showPwd = false
            this.vcodeText = '获取验证码'
            clearInterval(this.vcodeTimer)
          } else {
            Toast(response.data?.errorMsg)
          }
        })
        .catch(error => {
          alert(JSON.stringify(error))
          // console.log(error.message)
        })
    },
    // https://open.dingtalk.com/document/orgapp-client/jsapi-authentication
    // https://github.com/open-dingtalk/openapi-demo-java/blob/master/src/main/java/com/alibaba/dingtalk/openapi/demo/auth/AuthHelper.java 需要鉴权
    copyMfa() {
      if (document.execCommand) {
        let inputElement = document.getElementById('mfaCopyId')
        inputElement.select()
        if (document.execCommand('Copy')) {
          Toast('复制成功')
        }
      }
      dd.biz.clipboardData.setData({
        text: this.userInfo.mfa,
        onSuccess: function(res) {
          // 调用成功时回调
          Toast('复制成功')
        },
        onFail: function(err) {
          // 调用失败时回调
          console.log(err)
        }
      })
    },
    openResetPwd() {
      this.showPwd = true
      // if (__DEV__) {
      //   this.showPwd = true
      // } else {
      //   dd.device.notification.prompt({
      //     message: '重置密码',
      //     buttonLabels: ['取消', '确认'],
      //     onSuccess: (res) => {
      //       // 调用成功时回调
      //       console.log(res)
      //       if (res.buttonIndex === 1) {
      //         this.passwd = res.value
      //         this.resetPwd()
      //       }
      //     }
      //   })
      // }
    },
    resetMfa() {
      console.info(dd.device.notification.extendModal)
      dd.device.notification.extendModal({
        buttonLabels: ['了解更多', '知道了'],
        onSuccess: function(res) {
        // 调用成功时回调
          console.log(res)
        },
        onFail: function(err) {
        // 调用失败时回调
          console.log(err)
        }
      })
    },
    getResource() {
      axios.post(domain + '/api/getResource', {
        username: this.userInfo.email.split('@')[0],
        owner: this.userInfo.name
      })
        .then(response => {
          const data = response?.data?.data?.data
          if (data) {
            this.resource = data.filter(item => item.Value)
          }
        })
        .catch(error => {
          // alert(JSON.stringify(error))
          console.log(error.message)
        })
    },
    getResourceDetail(item) {
      axios.post(domain + '/api/getResourceDetail', {
        username: this.userInfo.email.split('@')[0],
        owner: this.userInfo.name,
        zone: item.Key.toLowerCase()
      })
        .then(response => {
          const data = response?.data?.data?.data
          if (data) {
            console.info(data)
            if (data?.Key === 'ALIYUN') {
              for (const key in data.Value) {
                const values = data.Value[key]
                if (values !== 'null') {
                  data.Value[key] = JSON.parse(values)
                }
              }
            }
            this.resourceDetail = data
            this.showResourceDetail = true
          }
        })
        .catch(error => {
          console.log(error)
          alert(JSON.stringify(error))
          // console.log(error.message)
        })
    },
    openChat() {
      dd.biz.chat.openSingleChat({
        corpId: 'ding5f5719d799d6f976',
        userId: '16143302404924755',
        onSuccess: function(res) {
        // 调用成功时回调
          console.log(res)
        },
        onFail: function(err) {
        // 调用失败时回调
          console.log(err)
        }
      })
    }
  }
}
